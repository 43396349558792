import { format } from 'date-fns';
import {PATH_TYPE, PLACEMENT_ISSUE_REASON, SHIFT_ALLOCATION_ID_SUFFIX} from '../../utils/constants';
import {ALLOCATION_TYPE, MAX_CLUSTER_CHUNK_SIZE, prepareModelForBackend, PROCESS_PATHS, PROCESS_TYPE} from '../data';

export const getActualHeadcountsForBoardEntry = (boardEntry) => {
  return boardEntry.assignments.reduce((acc, processPathEntry) => {
    return acc + 1;
  }, 0);
};

export const getRequiredHeadcountForBoardEntry = (boardEntry) => {
  return boardEntry.processPathEntries.reduce((acc, processPathEntry) => {
    return acc + (processPathEntry.minResources || 0);
  }, 0);
};

export const getRequiredHeadcountsForProcessPath = (boardEntry, processId) => {
  const processPathEntry = boardEntry.processPathEntries.find((entry) => entry.process === processId);
  if (processPathEntry) {
    return processPathEntry.minResources || Infinity;
  }
  return Infinity;
};

export const hasPlacementIssues = (associateInfo, assignedProcess, boardEntry) => {
  if (!associateInfo.trainedPaths.map((d) => d.toLowerCase()).includes(assignedProcess.toLowerCase())) {
    return {
      hasIssue: true,
      reason: PLACEMENT_ISSUE_REASON.UNTRAINED_PATH
    };
  } else if (associateInfo.disallowedPaths.map((d) => d.toLowerCase()).includes(assignedProcess.toLowerCase())) {
    return {
      hasIssue: true,
      reason: PLACEMENT_ISSUE_REASON.DISALLOWED_PATH
    };
  } else  if (associateInfo.trackingPath && ! (associateInfo.trackingPath.toLowerCase().localeCompare(assignedProcess.toLowerCase())  === 0)) {
    return {
      hasIssue: true,
      reason: PLACEMENT_ISSUE_REASON.TRACKING_TO_DIFFERENT_PATH + associateInfo.trackingPath
    };
  }
  else {
    const requiredHeadcount = getRequiredHeadcountsForProcessPath(boardEntry, assignedProcess);
    const actualHeadcount = getHeadcountForProcessPath(boardEntry, assignedProcess);
    if (actualHeadcount > requiredHeadcount) {
      return {
        hasIssue: true,
        reason: PLACEMENT_ISSUE_REASON.OVERSTAFFED
      };
    }
  }
  return {
    hasIssue: false
  };
};

export const getHeadcountForProcessPath = (boardEntry, processPathId) => {
  return boardEntry.assignments.filter((assignment) => assignment.allocationAttrs.process === processPathId).length;
};

export const getHeadcountForProcessPaths = (boardEntry) => {
  return boardEntry.processPathEntries.map((entry, index) => {
    const required = entry.minResources;
    const actualHeadcount = getHeadcountForProcessPath(boardEntry, entry.process);
    return {
      process: entry.process && entry.process.replace('_', ' '),
      required,
      actualHeadcount
    };
  });
};

export const getCardHeadcountStatus = (boardEntry) => {
  const headcounts = getHeadcountForProcessPaths(boardEntry);
  return headcounts.every((headCount) => headCount.actualHeadcount >= headCount.required);
};

export const getCardPlacementIssue = (boardEntry, associateInfo) => {
  for (const assignment of boardEntry.assignments) {
    const associateAlias = assignment.resourceId;

    if (
      !associateInfo[associateAlias] ||
      !associateInfo[associateAlias].trainedPaths.includes(assignment.allocationAttrs.process) ||
      associateInfo[associateAlias].disallowedPaths.includes(assignment.allocationAttrs.process)
    ) {
      return true;
    }
  }
  return false;
};

export const getProcessCardHeaderColor = (boardEntry, associateInfo) => {
  if (getCardPlacementIssue(boardEntry, associateInfo)) {
    return '#AD0A30';
  }
  if (!getCardHeadcountStatus(boardEntry)) {
    return '#00688D';
  }
  return '#B1BAC3';
};

export const getClusterCardHeaderColor = (boardEntry, associateInfo, clusterDefinitions) => {
  if (getCardPlacementIssue(boardEntry, associateInfo)) {
    return '#AD0A30';
  }
  if (
    getUnassignedClusterChunks(boardEntry, PROCESS_PATHS.STOW, clusterDefinitions).length === 0 &&
    getUnassignedClusterChunks(boardEntry, PROCESS_PATHS.PICK, clusterDefinitions).length === 0
  ) {
    return '#00688D';
  }
  return '#B1BAC3';
};

export const getPlanChangeViolations = (result, processInfo, associateInfo, processPathChosen) => {
  const toBoardEntryId = result.destination.droppableId.split('-')[0];
  const toBoardEntry = processInfo.find((b) => b.id === toBoardEntryId);
  if (!toBoardEntry) {
    return [];
  }

  const violations = [];
  // checking if HC is over the required after selecting the process
  const headCounts = getHeadcountForProcessPaths(toBoardEntry);
  const subprocessHC = headCounts.find((hc) => hc.process === processPathChosen);
  if (subprocessHC) {
    if (subprocessHC.actualHeadcount + 1 > subprocessHC.required) {
      violations.push({
        violationReason: 'overstaffing'
      });
    }
  }

  const associate = associateInfo[result.draggableId];
  if (associate) {
    // check if associate has the required trainings.
    if (processPathChosen && !associate.trainedPaths.includes(processPathChosen)) {
      violations.push({
        violationReason: 'training',
        type: processPathChosen
      });
    } else if (
      toBoardEntry !== PROCESS_PATHS.BENCH &&
      result.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP) &&
      !associate.trainedPaths.includes(result.destination.droppableId.split('-')[1])
    ) {
      violations.push({
        violationReason: 'training',
        type: result.destination.droppableId.split('-')[1]
      });
    } else if (
      toBoardEntry !== PROCESS_PATHS.BENCH &&
      !result.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP) &&
      toBoardEntry.processPathEntries.length === 1 &&
      !associate.trainedPaths.includes(toBoardEntry.processPathEntries[0].process)
    ) {
      violations.push({
        violationReason: 'training',
        type: toBoardEntry.processPathEntries[0].process
      });
    }
    // check if associate is prohibited from path
    if (
      associate.disallowedPaths.includes(processPathChosen) ||
      associate.disallowedPaths.includes(result.destination.droppableId.split('-')[1]) ||
      (toBoardEntry.processPathEntries.length === 1 &&
        associate.disallowedPaths.includes(toBoardEntry.processPathEntries[0].process))
    ) {
      violations.push({
        violationReason: 'jobRotation',
        type: processPathChosen
      });
    }
  }
  return violations;
};

export const getUnassignedClustersMap = (assignments, processPathId, minAisleNo, maxAisleNo) => {
  const assignmentClusterMap = {};
  for (let i = minAisleNo; i <= maxAisleNo; i++) {
    assignmentClusterMap[i] = false;
  }

  assignments
    .filter((assignment) => assignment.allocationAttrs.process === processPathId)
    .forEach((assignment, index) => {
      if (assignment.allocationAttrs.aisles) {
        assignment.allocationAttrs.aisles.forEach((aisle) => {
          assignmentClusterMap[parseInt(aisle.replace(/\w-/, ''))] = true;
        });
      }
    });
  return assignmentClusterMap;
};

export const getChunksFromClusterMapHelper = (assignmentClusterMap, startAisleNo, maxAisleNo) => {
  let aisleNo;

  let maxUnassignedAisleNo = startAisleNo;
  for (aisleNo = startAisleNo + 1; aisleNo <= Math.min(startAisleNo + MAX_CLUSTER_CHUNK_SIZE, maxAisleNo); aisleNo++) {
    if (!assignmentClusterMap[aisleNo]) {
      maxUnassignedAisleNo = aisleNo;
    } else {
      return {
        minUnassignedAisleNo: startAisleNo,
        maxUnassignedAisleNo,
        nextIndex: aisleNo + 1
      };
    }
  }

  return {
    minUnassignedAisleNo: startAisleNo,
    maxUnassignedAisleNo,
    nextIndex: aisleNo
  };
};

export const getChunksFromClusterMap = (assignmentClusterMap, minAisleNo, maxAisleNo) => {
  let currentAisleNo = minAisleNo;
  const unassignedClusterChunkList = [];

  while (currentAisleNo <= maxAisleNo) {
    if (!assignmentClusterMap[currentAisleNo]) {
      const obj = getChunksFromClusterMapHelper(assignmentClusterMap, currentAisleNo, maxAisleNo);
      currentAisleNo = obj.nextIndex;
      unassignedClusterChunkList.push({
        minUnassignedAisleNo: obj.minUnassignedAisleNo,
        maxUnassignedAisleNo: obj.maxUnassignedAisleNo
      });
    } else {
      currentAisleNo++;
    }
  }
  return unassignedClusterChunkList;
};

export const aisleAssignmentHeaderFormatter = (assignment) => {
  return assignment.allocationAttrs.aisles
    ? `${assignment.allocationAttrs.aisles[0].replace(/\w-/, '')}-${assignment.allocationAttrs.aisles[
        assignment.allocationAttrs.aisles.length - 1
      ].replace(/\w-/, '')}`
    : 'Empty Aisle';
};

export const clusterAssignmentHeaderFormatter = (assignment) => {
  return assignment.allocationAttrs.clusters ? `${assignment.allocationAttrs.clusters.join('')}` : '';
};

export const getUnassignedClusterChunks = (boardEntry, processPathId, clusterDefinitions) => {
  const clusterDefinition = clusterDefinitions.find(
    (clusterDefinition) => `cluster_${clusterDefinition.identifier}` === boardEntry.id
  );

  const aisleDefinitions = clusterDefinition.sortAisleReservations.map((d) =>
    parseInt(d.identifier.replace(/\w-/, ''))
  );

  const minAisleNo = Math.min(...aisleDefinitions);
  const maxAisleNo = Math.max(...aisleDefinitions);

  const assignmentClusterMap = getUnassignedClustersMap(boardEntry.assignments, processPathId, minAisleNo, maxAisleNo);
  return getChunksFromClusterMap(assignmentClusterMap, minAisleNo, maxAisleNo);
};

export const getAislesOptionsForBoardEntry = (boardEntry, clusterDefinitions) => {
  const clusterDefinition = clusterDefinitions.find(
    (clusterDefinition) => `cluster_${clusterDefinition.identifier}` === boardEntry.id
  );

  return clusterDefinition.sortAisleReservations
    .map((d) => parseInt(d.identifier.replace(/\w-/, '')))
    .sort((a, b) => a - b);
};

export const getAssociateInfoFromProcessAssignments = (assignments, allAssociateInfo) => {
  const filteredAssociateInfo = {};
  assignments.forEach((assignment, index) => {
    const associateAlias = assignment.resourceId;
    if (allAssociateInfo[associateAlias]) {
      filteredAssociateInfo[associateAlias] = allAssociateInfo[associateAlias];
    } else {
      console.log('Associate not found: ', associateAlias);
    }
  });
  return sortAssociatesByName(filteredAssociateInfo);
};

export const getAssignmentsForProcessPath = (processInfo, processPath) => {
  let assignments;
  if (processInfo.find((process) => process.id === processPath)) {
    assignments = processInfo.find((process) => process.id === processPath).assignments;
  } else {
    assignments = [];
  }
  return assignments;
};

export const getUpdatedProcessInfoWithAssociatesToFiveSProcessPath = (processInfo, associateInfo) => {
  const assignments = getAssignmentsForProcessPath(processInfo, PROCESS_PATHS.BENCH);

  const clockedInResources = [];
  const clockedOutResources = [];

  Object.values(getAssociateInfoFromProcessAssignments(assignments, associateInfo)).forEach((associate) => {
    if (associate.clockedInStatus) {
      clockedInResources.push(associate);
    } else {
      clockedOutResources.push(associate);
    }
  });

  const fiveSAssignments = [...clockedInResources].map((resource) => {
    return {
      resourceId: resource.associateId,
      boardEntity: PROCESS_PATHS.FIVE_S,
      allocationAttrs: {
        process: PROCESS_PATHS.FIVE_S,
        type: ALLOCATION_TYPE.PROCESS,
        pathType: PATH_TYPE.INDIRECT
      }
    };
  });

  const fiveSProcessInfo = {
    id: PROCESS_PATHS.FIVE_S,
    title: PROCESS_PATHS.FIVE_S,
    type: PROCESS_TYPE.GROUP,
    allocationType: ALLOCATION_TYPE.PROCESS,
    assignments: fiveSAssignments,
    processPathEntries: []
  };


  return [...processInfo, fiveSProcessInfo];
};

export const renderShift = (shift) => {
  return `${format(new Date(shift.start), 'HH:mm')} - ${format(new Date(shift.end), 'HH:mm')}`;
};

const sortAssociatesByName = (associatesInfo) => {
  return Object.values(associatesInfo).sort((a, b) => {
    const nameA = a.name ? a.name.toLowerCase() : '';
    const nameB = b.name ? b.name.toLowerCase() : '';
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};
