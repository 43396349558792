import React, { useState } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import Column from '@amzn/meridian/column';
import { upsertInductOrAslLines } from './dragAndDropUtils';

const UpsertInductAndAslLinesModel = ({
  minLines,
  maxLines,
  adminSettings,
  shiftAllocationModel,
  shiftAllocationDispatch,
  setIsBoardUpdated
}) => {
  const lines = Array.from({ length: maxLines - minLines + 1 }, (_, i) => minLines + i);

  const [open, setOpen] = useState(false);
  const [inductLines, setInductLines] = useState(0);
  const [aslLines, setAslLines] = useState(0);
  const onClickButton = () => setOpen(true);

  const onClose = () => {
    setOpen(false);
    setAslLines(0);
    setInductLines(0);
  };

  const onClickConfirmButton = () => {
    const linesToUpsert = [];

    if (inductLines) {
      linesToUpsert.push({ process: 'Induct', lines: inductLines });
    }

    if (aslLines) {
      linesToUpsert.push({ process: 'ASL', lines: aslLines });
    }
    upsertInductOrAslLines(linesToUpsert, adminSettings, shiftAllocationModel, shiftAllocationDispatch);

    setIsBoardUpdated(inductLines || aslLines);
    setOpen(false);
    setAslLines(0);
    setInductLines(0);
  };
  return (
    <React.Fragment>
      <Button type="tertiary" size="small" onClick={onClickButton}>
        Inducts
      </Button>
      <Modal
        title="Induct Manager"
        open={open}
        onClose={onClose}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby="modal-description"
        bodySpacingInset="400">
        <div style={{ marginBottom: '2rem' }}>
          <div style={{ marginBottom: '2rem' }}>
            <Row>
              <Text id="modal-description">{'Select how many Induct lines and ASL lines that needs to be open'}</Text>
            </Row>
          </div>
          <Row alignmentHorizontal="center" alignmentVertical="center" widths="fit">
            <Text id="modal-description">{'Inducts'}</Text>
            <Column>
              <Select value={inductLines} onChange={setInductLines} size="small" width={75}>
                {lines.map((line) => (
                  <SelectOption key={line} value={line} label={line} />
                ))}
              </Select>
            </Column>
            <Text id="modal-description">{'ASLs'}</Text>
            <Column>
              <Select value={aslLines} onChange={setAslLines} size="small" width={75}>
                {lines.map((line) => (
                  <SelectOption key={line} value={line} label={line} />
                ))}
              </Select>
            </Column>
          </Row>
        </div>

        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" size="small" onClick={onClickConfirmButton}>
              Confirm
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default UpsertInductAndAslLinesModel;
