import React, { createContext, useCallback, useContext, useEffect, useReducer, useState, useRef } from 'react';

import { ShiftAllocationReducer } from '../reducer/ShiftAllocationReducer.js';
import { GlobalStateContext } from '../../global/context/GlobalStateContext';

import ErrorView from '../view/ErrorSection.js';
import LoadingView from '../../handler/loadingHandler/LoadingView.js';
import ErrorSection from '../view/ErrorSection.js';
import {
  TIME_WINDOW_PHASES,
  getTimeWindowAllocationKey,
  processAllocationData,
  processAvailableCycles,
  processAvailableShifts,
  processAssociates,
  DEFAULT_SHIFT_ALLOCATION_MODEL
} from '../data.js';
import {CYCLES, PLAN_STATUS, PLAN_VERSIONS, VLAB_ROOT_STATION} from '../../utils/constants.js';
import { getStationCode } from '../../utils/networkUtil.js';
import { RESET_MODEL, RESOURCE_STATUS_ENRICHMENT, SET_MODEL } from '../action/ShiftAllocationAction.js';
import apis from '../../utils/apis.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.js';
import { getAdminSettings } from '../../utils/AdminSettings';
import {getRegion} from "../../utils/helpers";

export const ShiftAllocationContext = createContext();
export const nodeId = getStationCode();

const DELAY = 1000 * 60;
const PLAN_POLL_DELAY = 1000 * 10;

const ShiftAllocationContextProvider = (props) => {
  const location = useLocation();
  const [adminSettings, setAdminSettings] = useState({});
  const { date, setDate } = useContext(GlobalStateContext);
  const enrichmentPollingId = useRef(null);
  const planPollingId = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [currPlan, setCurrPlan] = useState(null);
  const [loadingMsg, setLoadingMsg] = useState('Getting the Labor Allocation from the Backend');
  const [alertMsg, setAlertMsg] = useState();
  const [isBoardUpdated, setIsBoardUpdated] = useState(false);
  const [shiftAllocationModel, shiftAllocationDispatch] = useReducer(
    ShiftAllocationReducer,
    DEFAULT_SHIFT_ALLOCATION_MODEL
  );
  const [cachedResponse, setCachedResponse] = useState(null);
  const [availableAssociatesVisibility, setAvailableAssociatesVisibility] = useState(false);

  const [storedBoardAllocations, setStoredBoardAllocations] = useState({});

  const displayError = useCallback((errorMessage = null) => {
    if (errorMessage) {
      setAlertMsg(errorMessage);
    }
    if (planPollingId.current) {
      clearInterval(planPollingId.current);
      planPollingId.current = null;
    }
    setIsError(true);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      const settings = await getAdminSettings(VLAB_ROOT_STATION[getRegion()]);
      setAdminSettings(settings);
    })();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('shift-allocation') && currPlan === null) {
      getShiftAllocationPlan(date);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('shift-allocation')) {
      setCachedResponse(null);
      getShiftAllocationPlan(date);
    }
  }, [date, currPlan]);

  const changePlanParameters = useCallback(
    ({ cycle = CYCLES.CYCLE_1, phase = TIME_WINDOW_PHASES.INDUCT_AND_STOW, shift }) => {
      if (!cachedResponse) {
        return;
      }

      const cycleOneAllocations = cachedResponse.output.resourceAllocationOutput.timeWindowAllocations.filter(
        (timeWindowAllocation) => timeWindowAllocation.timeWindowAttrs[0].cycle === CYCLES.CYCLE_1
      );

      const timeWindowAllocation = cycleOneAllocations.find((timeWindowAllocation) => {
        if (!shift) {
          return timeWindowAllocation.timeWindowAttrs[0].phase === phase;
        } else {
          return (
            timeWindowAllocation.timeWindowAttrs[0].phase === phase &&
            timeWindowAllocation.timeWindow.start === shift.start &&
            timeWindowAllocation.timeWindow.end === shift.end
          );
        }
      });

      if (!timeWindowAllocation) {
        return;
      }

      if (shiftAllocationModel.boardAllocationKey === getTimeWindowAllocationKey(timeWindowAllocation)) {
        return;
      }

      const associates = processAssociates(
        cachedResponse.input['resourceProcessPathAttributeListInput'],
        cachedResponse.output.resourceAllocationOutput['resourceRecommendedProcesses']
      );

      setStoredBoardAllocations((prevState) => {
        prevState[shiftAllocationModel.boardAllocationKey] = shiftAllocationModel;
        return prevState;
      });

      if (storedBoardAllocations[getTimeWindowAllocationKey(timeWindowAllocation)]) {
        shiftAllocationDispatch({
          type: SET_MODEL,
          payload: {
            ...storedBoardAllocations[getTimeWindowAllocationKey(timeWindowAllocation)]
          }
        });
      } else {
        shiftAllocationDispatch({
          type: SET_MODEL,
          payload: {
            ...processAllocationData(
              timeWindowAllocation.allocationBoard,
              associates,
              timeWindowAllocation.availableResourceIds
            ),
            boardAllocationKey: getTimeWindowAllocationKey(timeWindowAllocation),
            availableShifts: processAvailableShifts(cycleOneAllocations),
            availableCycles: processAvailableCycles(cycleOneAllocations),
            clusterDefinitions: cachedResponse.input.sortLocationReservations
          }
        });
      }
    },
    [shiftAllocationModel, cachedResponse, storedBoardAllocations]
  );

  useEffect(() => {
    if (planPollingId) {
      clearInterval(planPollingId.current);
      planPollingId.current = null;
    }

    const planPoller = async () => {
      const res = await apis['GET_PLAN'](
        {
          query: {
            planId: currPlan
          }
        },
        () => displayError()
      );
      if (res && res.planId) {
        if (res.status === PLAN_STATUS.PENDING_CREATION) {
          return false;
        } else if (res.status === PLAN_STATUS.FAILED) {
          displayError('Virtual Labor Allocation Board Error');
          return;
        }
        const associates = processAssociates(
          res.input['resourceProcessPathAttributeListInput'],
          res.output.resourceAllocationOutput['resourceRecommendedProcesses']
        );
        setCachedResponse(res);

        const cycleOneAllocations = res.output.resourceAllocationOutput.timeWindowAllocations.filter(
          (timeWindowAllocation) => timeWindowAllocation.timeWindowAttrs[0].cycle === CYCLES.CYCLE_1
        );

        const startAllocationOutput = cycleOneAllocations && cycleOneAllocations[0];

        if (!startAllocationOutput) {
          setIsLoading(false);
          displayError('There was an issue reading the Labor Allocation Board, please try again');
          return;
        }

        const processedBoard = processAllocationData(
          startAllocationOutput.allocationBoard,
          associates,
          startAllocationOutput.availableResourceIds
        );

        setStoredBoardAllocations({
          [getTimeWindowAllocationKey(startAllocationOutput)]: processedBoard
        });

        shiftAllocationDispatch({
          type: SET_MODEL,
          payload: {
            ...processedBoard,
            boardAllocationKey: getTimeWindowAllocationKey(startAllocationOutput),
            availableShifts: processAvailableShifts(cycleOneAllocations),
            // availableCycles: processAvailableCycles(cycleOneAllocations)
            availableCycles: [CYCLES.CYCLE_1],
            clusterDefinitions: res.input.sortLocationReservations,
            laborTrackingStatus: res.laborTrackingStatus
          }
        });
        clearInterval(planPollingId.current);
        planPollingId.current = null;
        setIsLoading(false);
        return true;
      }
      return false;
    };

    if (currPlan) {
      planPoller();
      planPollingId.current = setInterval(planPoller, PLAN_POLL_DELAY);
    }
  }, [currPlan, shiftAllocationDispatch]);

  useEffect(() => {
    if (enrichmentPollingId) {
      clearInterval(enrichmentPollingId.current);
      enrichmentPollingId.current = null;
    }

    const enrichmentPoller = async () => {
      let enrichment = await apis['ALLOCATION_ENRICHMENT'](
        {
          query: {
            planId: currPlan
          }
        },
        () => null
      );

      shiftAllocationDispatch({
        type: RESOURCE_STATUS_ENRICHMENT,
        payload: {
          resourceStatuses: enrichment.ResourceEnrichmentList
        }
      });
    };

    if (currPlan) {
      enrichmentPoller();
      enrichmentPollingId.current = setInterval(enrichmentPoller, DELAY);
    }

    return () => clearInterval(enrichmentPollingId.current);
  }, [currPlan, shiftAllocationDispatch]);

  const getShiftAllocationPlan = async (date) => {
    // Add backend API call fetch latest labor allocation plan
    setIsLoading(true);
    setIsError(false);

    if (nodeId == null) setIsError(true);

    setIsLoading(true);
    setIsError(false);

    shiftAllocationDispatch({
      type: RESET_MODEL,
      payload: {}
    });
    let allPlans = await apis['GET_ALL_PLANS'](
      {
        query: {
          date,
          nodeId: getStationCode()
        }
      },
      displayError
    );
    allPlans = (allPlans['shiftPlans'] || [])
      .filter((plan) => plan.planVersion === PLAN_VERSIONS.LABOR_ALLOCATION_V2)
      .sort((a, b) => b.updatedAt - a.updatedAt);
    const plan = allPlans[0];
    if (!plan) {
      shiftAllocationDispatch({
        type: RESET_MODEL,
        payload: {}
      });
      setCurrPlan(null);
      setIsLoading(false);
      return;
    }
    setCurrPlan(plan.planId);
  };

  if (false) return <ErrorView />;
  else {
    return (
      <ShiftAllocationContext.Provider
        value={{
          shiftAllocationModel,
          shiftAllocationDispatch,
          availableAssociatesVisibility,
          setAvailableAssociatesVisibility,
          changePlanParameters,
          storedBoardAllocations,
          cachedResponse,
          setCurrPlan,
          alertMsg,
          isBoardUpdated,
          setIsBoardUpdated,
          date,
          setDate,
          adminSettings
        }}
      >
        {props.children}
        {isLoading && <LoadingView loadingMsg={loadingMsg} />}
      </ShiftAllocationContext.Provider>
    );
  }
};

export default ShiftAllocationContextProvider;
