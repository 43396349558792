import React from 'react';
import Row from '@amzn/meridian/row';

export const ProcessViewLegend = ({}) => {
  const Legend = ({ border, backgroundColor = null }) => {
    const circleStyle = {
      width: '20px',
      height: '20px',
      border: `${border}`,
      borderRadius: '50%',
      backgroundColor: 'transparent'
    };

    return <div style={circleStyle}></div>;
  };

  const Title = ({ color, text }) => {
    const titleStyle = {
      fontSize: '15px',
      color: `${color}`,
      font: 'bookerly'
    };

    return <div style={titleStyle}>{text}</div>;
  };

  return (
    <Row spacing={'500'} spacingInset={'200'} alignmentHorizontal="center" >
      <Row spacing={'200'}>
        <Legend border={'2px solid #89929A'}></Legend>
        <Title text={'Clocked In'} color={'#89929A'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={'2px dashed #89929A'}></Legend>
        <Title text={'Not Clocked In'} color={'#89929A'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={'2px dashed #0085b3'}></Legend>
        <Title text={'Recommended'} color={'#0085b3'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={'2px solid #AD0A30'}></Legend>
        <Title text={'Issue'} color={'#AD0A30'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={'2px dashed #FAD000'}></Legend>
        <Title text={'25% Under Staffed'} color={'#FAD000'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={'2px dashed #AD0A30'}></Legend>
        <Title text={'50% Over Staffed'} color={'#AD0A30'} />
      </Row>
    </Row>
  );
};
